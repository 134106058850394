* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  width: 100Vw;
  background: linear-gradient(to bottom left, #051937, #004d7a, #008793, #00bf72, #a8eb12);
}

@font-face {
  font-family: CormorantGaramondBold;
  src: url('../public/CormorantGaramond-Bold.ttf');
}

@font-face {
  font-family: Lora;
  src: url('../public/Lora-VariableFont_wght.ttf');
}
